import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import iconDifferentation from 'stile-shared/assets/icons/icon_differentiation.svg';
import iconDiscussions from 'stile-shared/assets/icons/icon_discussions.svg';
import iconFeedback from 'stile-shared/assets/icons/icon_feedback.svg';
import iconHardProblems from 'stile-shared/assets/icons/icon_hard_problems.svg';
import iconHigherMarks from 'stile-shared/assets/icons/icon_higher_marks.svg';
import iconLiteracy from 'stile-shared/assets/icons/icon_literacy.svg';
import iconPrinting from 'stile-shared/assets/icons/icon_printing.svg';
import iconQuietStudents from 'stile-shared/assets/icons/icon_quiet_students.svg';
import iconStem from 'stile-shared/assets/icons/icon_stem.svg';
import iconTechnology from 'stile-shared/assets/icons/icon_technology.svg';
import iconTomorrowsJobs from 'stile-shared/assets/icons/icon_tomorrows_jobs.svg';
import iconUpToDate from 'stile-shared/assets/icons/icon_up_to_date.svg';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { IconCardsBlock } from 'stile-shared/src/components/2020/blocks/IconCardsBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { P } from 'stile-shared/src/components/2020/common/Primitives';

import { getPageData } from 'utils/getPageData';

import { PageLayout } from 'templates/2020/PageLayout';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';

export { Head } from 'templates/2020/Head';

function BenefitsPage(props: PageProps) {
  const { hero } = getPageData(props.location.pathname);

  return (
    <PageLayout {...props}>
      <HeroBlock
        {...hero}
        image={
          <StaticImage
            loading="eager"
            aspectRatio={2.37}
            src="../../../../stile-shared/assets/images/benefits/cover_benefits.png"
            alt="A teacher and students discussing real-world science together"
          />
        }
      />

      <TextBlock>
        <P>Stile goes beyond simply covering the science curriculum.</P>
        <P>
          Stile nurtures informed, reflective young citizens. Citizens that are ready to engage in a
          world rife with “fake news” and “alternative facts”. Ready to participate in public
          discourse on climate change or genetically modified food. Ready to help tackle tomorrow’s
          big issues.
        </P>
        <P>
          We think that’s a pretty big benefit to students and to society, but here are a few more
          benefits we think you’ll notice...
        </P>
      </TextBlock>

      <IconCardsBlock
        max="1200px"
        cards={[
          {
            title: 'More classroom discussions around real-world science',
            icon: { src: iconDiscussions },
            text: 'Every unit of work in Stile is set in the context of real-world science discoveries and events, helping spark conversation and debate in the classroom.',
          },
          {
            icon: { src: iconDifferentation },
            title: 'Teachers differentiating their instruction more frequently',
            text: 'Every lesson can be customized, allowing teachers to quickly tailor content to the needs of individual students.',
          },
          {
            icon: { src: iconHardProblems },
            title: 'Students pondering hard problems',
            text: 'Every lesson is scaffolded from lower- to higher-order thinking, helping to ensure that every student is challenged at their level.',
          },
          {
            icon: { src: iconFeedback },
            title: 'Students receiving better feedback, more often',
            text: 'Stile provides teachers with the ability to see their students’ work at any time, allowing them to provide more feedback, and in a more timely manner. Fully worked model answers make it easier to give specific feedback.',
          },
          {
            icon: { src: iconTomorrowsJobs },
            title: 'Naturally nurture skills for tomorrow’s jobs',
            text: 'We’ve analyzed the research on which skills will be the most important for tomorrow’s jobs. These skills – such as critical thinking, creative problem-solving and evidence-based reasoning – are carefully interwoven through all of our resources.',
          },
          {
            icon: { src: iconUpToDate },
            title: 'Always up-to-date with new lessons monthly',
            text: 'Every month, Stile makes brand-new science resources available to teachers. This means that your team always have access to the very best resources, and your students are learning in the context of the news and events happening around them.',
          },
          {
            icon: { src: iconTechnology },
            title: 'Classroom technology that enhances learning',
            text: 'Stile has been designed to make excellent use of smartboards and student devices. Rather than being sidelined, you’ll notice teachers at the heart of their classrooms, using technology peripherally in a blended learning environment.',
          },
          {
            icon: { src: iconStem },
            title: 'Improved uptake of STEM subjects in senior years',
            text: 'Schools have told us that Stile has been a key factor in boosting the uptake of STEM subjects in senior years, both for boys and girls.',
          },
          {
            icon: { src: iconPrinting },
            title: 'Less printing and photocopying',
            text: 'We’ve had schools measure how much money they spent on printing and photocopying before and after adopting Stile. Some reported savings of up to $33 per student.',
          },
          {
            icon: { src: iconHigherMarks },
            title: 'Students achieving higher marks in assessments',
            text: 'A number of schools have told us that Stile has boosted their results, both in internal summative assessments and in external science examinations (such as on standardized state assessments).',
          },
          {
            icon: { src: iconLiteracy },
            title: 'Improve student literacy',
            text: 'Embedded within every Stile unit are science news stories from Cosmos Magazine. These stories are written with age-appropriate language and are accompanied with audio narration to help improve student reading and comprehension.',
          },
          {
            icon: { src: iconQuietStudents },
            title: 'Quieter students find their voice',
            text: 'Quieter students often “fly under the radar”, slowly slipping behind the rest of the class. Stile’s collaboration tools create a safe, comfortable environment for all students to have their voices heard in class conversations and debates.',
          },
        ]}
      />

      <NextPageBlock path="/why-choose-stile/evidence-of-efficacy/" />

      <CtaBlock />
    </PageLayout>
  );
}

export default BenefitsPage;
