import * as React from 'react';

import { theme } from '../../../../styles/2020/theme';

import { H2, P, Section, Text } from '../../common/Primitives';
import { Center } from '../../layout/Center';
import { Column } from '../../layout/Column';
import { FluidGrid } from '../../layout/FluidGrid';
import { Icon } from '../../layout/Icon';

type IconCardVariant = 'compact' | 'large';

type IconCardsBlockProps = React.ComponentProps<typeof Section> & {
  variant?: IconCardVariant;
  title?: string;
  max?: React.ComponentProps<typeof Center>['max'];
  cards: React.ComponentProps<typeof IconCard>[];
};

export function IconCardsBlock({
  max = theme.space.measure,
  variant = 'large',
  cards,
  title,
  ...props
}: IconCardsBlockProps) {
  return (
    <Section {...props}>
      <Center max={max}>
        <Column align="center" space={theme.space.lToXl}>
          {title && <H2>{title}</H2>}

          {variant === 'compact' ? (
            <FluidGrid min="150px">
              {cards.map((card) => (
                <IconCard {...card} key={card.title} variant="compact" />
              ))}
            </FluidGrid>
          ) : (
            <FluidGrid min="240px" space={theme.space.l}>
              {cards.map((card) => (
                <IconCard {...card} key={card.title} />
              ))}
            </FluidGrid>
          )}
        </Column>
      </Center>
    </Section>
  );
}

type IconCardProps = React.ComponentProps<typeof Column> & {
  title: string;
  icon: React.ComponentProps<typeof Icon>;
  text?: string;
  variant?: IconCardVariant;
};

const IconCard = ({ title, text, icon, variant, ...props }: IconCardProps) => {
  if (variant === 'compact') {
    return (
      <Column space={theme.space.xxxs} align="center" {...props}>
        <Icon iconSize="50px" inline={false} {...icon} />
        <Text as="h3" size="s" textAlign="center" bold>
          {title}
        </Text>
      </Column>
    );
  }

  return (
    <Column space={theme.space.xxs} {...props}>
      <Icon iconSize="2.5em" inline={false} {...icon} />
      <Text as="h3" size="sm" bold>
        {title}
      </Text>
      {text && <P size="s">{text}</P>}
    </Column>
  );
};
