import * as React from 'react';

import styled, { css } from 'styled-components';

import type { StyledAsProps } from '../../../../types/props';

import { withListItemRole } from '../../../../utils/withListItemRole';

import { theme } from '../../../../styles/2020/theme';

type FluidGridProps = StyledAsProps & {
  /* Minimum width for each item */
  min?: React.CSSProperties['flexBasis'];
  /* Maximum width for each item */
  max?: React.CSSProperties['maxWidth'];
  /* Gap between elements */
  space?: React.CSSProperties['gap'];
  /* Justify content */
  justify?: React.CSSProperties['justifyContent'];
};

/**
 * Displays children in a flex grid, with `min` and `max` hints to control child sizing.
 */
export const FluidGrid = ({
  min = '16rem',
  max = '32rem',
  space = theme.space.m,
  justify = 'center',
  children,
  ...props
}: React.PropsWithChildren<FluidGridProps>) => {
  return (
    <FluidGridContainer min={min} max={max} space={space} justify={justify} role="list" {...props}>
      {React.Children.map(children, (child) => withListItemRole(child))}
    </FluidGridContainer>
  );
};

const FluidGridContainer = styled.div<FluidGridProps & { isEven: boolean }>(
  ({ min, max, space, justify }) => css`
    display: flex;
    flex-wrap: wrap;
    gap: ${space};

    justify-content: ${justify};

    & > * {
      flex: 1 1 ${min};
      max-width: ${max};
    }
  `
);
